.container {
  margin-top: 200px;
  text-align: center;
  h1 {
    font-family: "allotrope";
    color: #1b1363;
    margin-top: 40px;
    margin-bottom: 40px;
    &:hover {
      color: #29abe1;
      cursor: pointer;
    }
  }
  span {
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 900;
  }
}
.popUpContainer {
  position: fixed;
  top: 100px;
  right: 0;
  left: 0;
}
.popUp {
  position: absolute;
  top: 150px;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #1b1363;
  width: 60%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  * {
    color: white;
    font-family: "allotrope";
    font-size: 40px;
  }
  span {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 15px;
  }
}
@media (max-width: 600px) {
  .popUp {
    width: 70%;
    * {
      font-size: 30px;
    }
  }
}
@media (max-width: 400px) {
  .container {
    h1 {
      font-size: 1.5em;
    }
  }
  .popUp {
    width: 80%;
    * {
      font-size: 20px;
    }
  }
}
