.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainNav {
  @extend .flex;
  position: fixed;
  top: 0;
  height: 100px;
  // background-color: #d2dce2;
  background-color: white
}
.nav {
  @extend .flex;
  width: 40%;
  margin-right: 20px;
  font-family: "allotrope";
  color: #1b1363;
  font-size: 20px;
  & * {
    margin: 10px;
  }
  &:hover {
    cursor: pointer;
  }
}
.medicineLogo {
  width: 25%;
}
.signIn,
.registration {
  padding: {
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
  border-radius: 30px;
}
.signIn {
  background-color: #c0bfbb;
  box-shadow: -3px 3px 3px #595c5b;
}
.registration {
  background-color: #29abe1;
  box-shadow: -3px 3px 3px #595c5b;
}
@media (min-width: 1500px) {
  .nav {
    width: 40%;
  }
}
@media (max-width: 1150px) {
  .nav {
    width: 50%;
  }
}
@media (max-width: 990px) {
  .nav {
    width: 55%;
    margin-right: 5%;
    font-size: 18px;
  }
}
@media (max-width: 780px) {
  .nav {
    margin-right: 10px;
    width: 60%;
    font-size: 16px;
  }
}
@media (max-width: 700px) {
  .mainNav {
    height: 50px;
  }
  .nav {
    width: 65%;
    margin-right: 5px;
  }
}
@media (max-width: 600px) {
  .nav {
    width: 70%;
    margin-right: 0;
    font-size: 14px;
  }
}
@media (max-width: 530px) {
  .nav * {
    margin: 2px;
  }
}
@media (max-width: 450px) {
  .nav * {
    font-size: 10px;
  }
}
@media (max-width: 350px) {
  .signIn,
  .registration {
    padding-left: 5px;
    padding-right: 5px;
  }
}
