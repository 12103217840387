.popUp {
  position: absolute;
  top: 200px;
  right: 0;
  left: 0;
  border: 1px solid black;
  border-radius: 10px;
  width: 50%;
  height: 420px;
  background-color: #1b1363;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  & input {
    display: block;
    width: 300px;
    height: 30px;
    margin: 7px;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
  & button {
    display: block;
    width: 320px;
    height: 30px;
    margin: 7px;
    background-color: #c0bfbb;
    border: none;
    cursor: pointer;
  }
}
.title {
  font-family: "allotrope";
  font-size: 40px;
  color: white;
  margin: 10px;
}
.subtitle {
  font-family: "allotrope";
  font-size: 16px;
  color: white;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.closeButton {
  position: absolute;
  top: 0;
  right: 15px;
  cursor: pointer;
  color: white;
  font-size: 30px;
  font-family: "allotrope";
}
.error {
  @extend .subtitle;
  color: red;
}
@media (max-width: 1000px) {
  .popUp {
    width: 60%;
  }
}
@media (max-width: 800px) {
  .popUp {
    width: 70%;
  }
  .title {
    font-size: 30px;
  }
}
@media (max-width: 600px) {
  .popUp {
    width: 80%;
  }
  .title {
    font-size: 25px;
  }
  .subtitle {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .popUp {
    input {
      width: 200px;
      margin: auto;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    button {
      width: 220px;
    }
  }
}
@media (max-width: 400px) {
  .title {
    font-size: 20px;
  }
}
@media (max-width: 350px) {
  .popUp {
    width: 90%;
    input {
      width: 150px;
    }
    button {
      width: 170px;
    }
  }
}
