.window {
  position: fixed;
  width: 100vw;
  height: 100%;
  background-color: #d2dce2;
  top: 0;
  left: 0;
  z-index: -1;
}
.reset {
  background-color: #334b5f;
  margin: {
    top: 100px;
    bottom: 100px;
    left: auto;
    right: auto;
  }
  width: 600px;
  height: 600px;
}
.logo {
  text-align: center;
  img {
    width: 350px;
    margin-top: 30px;
  }
}
.box {
  font-family: Arial, Helvetica, sans-serif;
  color: #a8b1b9;
  background-color: white;
  width: 70%;
  min-height: 330px;
  margin: auto;
  margin-top: 30px;
  padding: 20px;
  text-align: left;
}
.hr {
  border: #bbc2c9 solid 1px;
}
.title {
  font-weight: bold;
  color: #617383;
}
.form {
  margin-bottom: 50px;
}
.input {
  height: 30px;
  width: 90%;
  margin: {
    top: 5px;
    bottom: 5px;
  }
  padding-right: 0;
  padding-left: 10%;
  border: solid #bbc2c9 1px;
  color: #617383;
  &:focus {
    box-shadow: 0 0 4px #334b5f;
    border: 1px rgb(152, 152, 254) solid;
    outline: none;
    border-radius: 3px;
  }
}
.button {
  background: #334b5f;
  color: white;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 30px;
  border: 1px #334b5f solid;
  &:hover {
    background: white;
    color: #334b5f;
    cursor: pointer;
  }
}
.footer {
  font-size: 12px;
  p {
    margin: 2px;
  }
  :nth-child(2) {
    color: #617383;
    font-weight: bold;
  }
}
@media (max-width: 650px) {
  .reset {
    width: 95%;
    margin-top: 10%;
  }
}
@media (max-width: 400px) {
  .logo {
    img {
      width: 100%;
    }
  }
}
