.meeting {
  margin-top: 100px;
  width: 100%;
}
.contact {
  text-align: right;
  margin-top: 100px;
  margin-bottom: 500px;
  font-family: "allotrope";
  font-size: 30px;
  color: #1b1363;
  padding-right: 50px;
  & * {
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
}
.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  margin-top: 0;
  padding-top: 130px;
  & * {
    width: 30%;
    border: black solid 2px;
    border-radius: 5px;
    cursor: pointer;
  }
}
.popUp {
  position: absolute;
  top: 300px;
  right: 0;
  left: 0;
  border-radius: 10px;
  width: 50%;
  height: 300px;
  background-color: #1b1363;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "allotrope";
  font-size: 40px;
  color: white;
}
.closeButton {
  position: absolute;
  top: 0;
  right: 15px;
  cursor: pointer;
}
@media (max-width: 800px) {
  .popUp {
    width: 65%;
  }
}
@media (max-width: 700px) {
  .cardsContainer {
    padding-top: 50px;
  }
}
@media (max-width: 600px) {
  .popUp {
    width: 75%;
    font-size: 30px;
    top: 200px;
    height: 200px;
  }
}
@media (max-width: 500px) {
  .popUp {
    font-size: 25px;
  }
}
@media (max-width: 400px) {
  .popUp {
    font-size: 20px;
    height: 100px;
    top: 170px;
  }
}
