.sites {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 10px;
  margin: 20px;
  background: #bdb8bd;
  &:hover {
    cursor: pointer;
    background: #908d90;
  }
  img {
    max-width: 200px;
    height: auto;
    margin: 20px;
  }
}
.sitesParent {
  margin-bottom: 50px;
}
